<div class="section home-bg">

    <div class="top-navigation margin-bottom-2 show-for-medium">
        <div class="row">
            <div class="medium-12 columns">
                <div class="flex-container align-center">
                    <div class="flex-item margin-right-1 flex-1">
                        <img class="show-for-medium" height="180" width="180" src="/assets/logo/bumn-logo.svg"
                            alt="image">
                    </div>

                 




                    <div  class="show-for-medium flex-item text-right margin-right-1">
                        <img class="margin-bottom-05" height="150" width="150" src="/assets/logo/mandiri-logo.svg"
                            alt="image">

                    </div>
                 
                </div>
            </div>
        </div>
    </div>




    <div class="top-navigation margin-bottom-2 show-for-small-only">
        <div class="row">
            <div class="medium-12 columns">
                <div class="flex-container">

                    <div class="flex-item margin-right-05 flex-1">
                        <img height="100" width="100" src="/assets/logo/bumn-logo.svg" alt="image">
                    </div>
                    <div class="flex-item">
                        <img class="margin-bottom-1" height="90" width="90" src="/assets/logo/mandiri-logo.svg"
                            alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="long-fade-in">
        <div class="padding-center">
            <div class="row margin-bottom-2">
                <div class="medium-12 columns">
                    <div class="row margin-bottom-2">
                        <div class="medium-7 columns">
                            <div>
                                <img class="show-for-small-only" height="200" width="200" src="/assets/logo/wmm2024.svg"
                                    alt="image">

                                <img class="show-for-medium" height="400" width="400" src="/assets/logo/wmm2024.svg"
                                    alt="image">

                            </div>
                            <div class="headline color-black">

                                The Wait is<br>
                                Almost Over...
                            </div>

                        </div>
                        <div class="medium-5 show-for-medium columns">
                            <iframe class="video-container" src="https://www.youtube.com/embed/5ejHHYxhAsc"
                                title="Recap Keseruan WMM 2023 14-20 Januari 2024" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>




                    <div class="row">
                        <div class="medium-6 columns margin-bottom-1">
                            <!-- <span class="section-subtitle color-black">
                                Pendaftaran akan dibuka mulai <br>
                                9 September 2024
                            </span> -->

                          


                        </div>
                        <div class="medium-6 columns medium-text-right">
                            <span class="section-title color-black uppercase"> #INSPIREInnovateIMPACT</span>

                        </div>


                        <div class="medium-12 columns show-for-small-only">
                            <iframe class="video-container" src="https://www.youtube.com/embed/5ejHHYxhAsc"
                                title="Recap Keseruan WMM 2023 14-20 Januari 2024" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>




                </div>


            </div>
        </div>

       <div class="row">
        <!-- <div class="medium-12 columns color-black">
            Bantuan Hub 0821-7773-3073

        </div> -->
       </div>


    </div>
</div>