<div class="section participant-gallery">


    <div class="padding-center">
        <div class="row">
            <div class="medium-12 columns">
                <div class="cursive-title color-white">Top 40</div>

                <div class="section-title color-white margin-bottom-1">

                    Pengumuman Finalis Top 20
                </div>


                <div class="about-nav flex-container">
                    <div *ngFor="let item of about_nav" class="flex-item">

                        <span (click)="selectCategory(item)" [ngClass]="{active : selectedCategory == item.key}" class="link-nav">
                            {{item.title}}
                        </span>
                    </div>


                </div>

            </div>

        </div>

        <div class="padding-center">

            <div class="fade-in" *ngIf="selectedCategory == 'beauty'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div class="participant-card">
                            <div class="image">
                                <img [src]="item.image_url" alt="">
                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="subtitle">
                                {{item.subtitle}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="fade-in" *ngIf="selectedCategory == 'fashion'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div class="participant-card">
                            <div class="image">
                                <img [src]="item.image_url" alt="">
                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="subtitle">
                                {{item.subtitle}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="fade-in" *ngIf="selectedCategory == 'food'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div class="participant-card">
                            <div class="image">
                                <img [src]="item.image_url" alt="">
                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="subtitle">
                                {{item.subtitle}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="fade-in" *ngIf="selectedCategory == 'tech'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div class="participant-card">
                            <div class="image">
                                <img [src]="item.image_url" alt="">
                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="subtitle">
                                {{item.subtitle}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>



</div>