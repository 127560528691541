import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-participant',
  templateUrl: './section-participant.component.html',
  styleUrls: ['./section-participant.component.scss']
})
export class SectionParticipantComponent implements OnInit {
  about_nav = [
    {
      'title' : 'Beauty & Wellness',
      'key' : 'beauty',
      'is_active' : true,
    },
    {
      'title' : 'Fashion',
      'key' : 'fashion',
    },
    {
      'title' : 'Food & Beverages',
      'key' : 'food',
    },
    {
      'title' : 'Tech Startup',
      'key' : 'tech',
    },
   
  ]


  participant = [
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    },
    {
      'title' : ' Ahmad Wicaksono',
      'subtitle' : 'IndahGlow',
      'image_url' : 'https://placehold.co/500x500/EEE/31343C',
      
    }
    
    
   
  ]
  selectedCategory: any;

  constructor() { }

  ngOnInit(): void {
    this.selectedCategory = 'beauty'
  }

  selectCategory(item){
    console.log(item)
    this.selectedCategory = item.key
  }

}
